import React, { useEffect, useState } from 'react';
import './SavedSelectionsModal.css';
import { fetchSavedSelections } from '../../services/api/fetchClippingsSelection';
import { deleteClippingSelection } from '../../services/api/fetchClippingsSelection';
import ReactLoading from 'react-loading';

const SavedSelectionsModal = ({ clientId, onClose, onUseSelection  }) => {
  const [savedSelections, setSavedSelections] = useState([]);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState("");

  const loadSavedSelections = async () => {
    setIsLoading(true);
    try {
      const data = await fetchSavedSelections(clientId);

      if (data && Array.isArray(data.results)) {
        setSavedSelections(data.results);
      } else {
        console.warn('Nenhuma seleção encontrada na resposta:', data);
        setSavedSelections([]);
      }
    } catch (err) {
      setError('Erro ao carregar seleções salvas.');
      console.error('Erro ao buscar seleções:', err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadSavedSelections();
  }, [clientId]);

  const handleDeleteSelection = async (id) => {
    setIsLoading(true);
    try {
      await deleteClippingSelection(id);
      setSavedSelections((prevSelections) =>
        prevSelections.filter((selection) => selection.id !== id)
      );
      await loadSavedSelections();
      setSuccessMessage(`Seleção excluída com sucesso.`);
      setTimeout(() => setSuccessMessage(''), 5000); 
    } catch (error) {
      setErrorMessage(`Erro ao excluir seleção, tente novamente.`);
      setTimeout(() => setErrorMessage(''), 5000); 
    } finally {
        setIsLoading(false);
      }
  };

  const handleUseSelection = (clippings) => {
    onUseSelection(clippings);
    onClose();
  };


  return (
    <div className="modal-overlay-saved-selections">
        {isLoading && (
        <div className={isLoading ? 'loading-overlay active' : 'loading-overlay'}>
            <ReactLoading type={'spin'} color={'#121926'} height={80} width={80} />
        </div>
        )}
        {successMessage && <div className="success-message">{successMessage}</div>}
        {errorMessage && <div className="error-message">{errorMessage}</div>}
      <div className="modal-content-saved-selections">
        <div className='modal-header-saved-selections'>
            <h2 className='title-saved-selections'>Seleções Salvas</h2>
            <button className="close-button-saved-selections" onClick={onClose}>
            X
            </button>
        </div>
        {error && <p>{error}</p>}
        {!isLoading && savedSelections.length === 0 && (
          <p>Nenhuma seleção salva encontrada. Por favor crie uma nova.</p>
        )}
        {!isLoading && savedSelections.length > 0 && (
          <table>
            <thead>
              <tr>
                <th>Nome</th>
                <th>Data</th>
                <th>Quantidade de Clippings</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {savedSelections.map((selection) => (
                <tr key={selection.id}>
                  <td>{selection.name}</td>
                  <td>{selection.created}</td>
                  <td>{selection.clippings.length}</td>
                  <td>
                    <button onClick={() => handleUseSelection(selection.clippings)} className='use-button-saved-selections'>
                      Usar
                    </button>
                    <button
                      onClick={() => handleDeleteSelection(selection.id)} className='delete-button-saved-selections'>
                      Excluir
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default SavedSelectionsModal;

import React from 'react';
import './ActionsModal.css';

const ActionsModal = ({ onEvaluateAll, onDeleteEvaluations, onDeleteClippings, onShowSavedSelections  }) => {
  return (
    <div className="actions-menu-modal">
      <button onClick={onEvaluateAll}>Avaliar Todas</button>
      <button onClick={onDeleteEvaluations}>Excluir Avaliação</button>
      <button onClick={onDeleteClippings}>Excluir Notícias</button>
      <button onClick={onShowSavedSelections}>Seleções Salvas</button>
    </div>
  );
};

export default ActionsModal;

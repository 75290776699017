import { post, del, get } from './generic.api';

export const createClippingSelection = async (data) => {
  try {
    const response = await post('clippings-selection/', data);
    return response;
  } catch (error) {
    console.error('Erro ao salvar a seleção de clippings:', error.response || error);
    throw error;
  }
};

export const fetchSavedSelections = async (clientId) => {
  try {
    const response = await get(`clippings-selection/?client=${clientId}`);
    return response;
  } catch (error) {
    console.error('Erro ao buscar seleções salvas:', error.response || error);
    throw error;
  }
};

export const deleteClippingSelection = async (id) => {
  try {
    const response = await del(`clippings-selection/${id}/`);
    return response;
  } catch (error) {
    console.error('Erro ao excluir seleção:', error);
    throw error;
  }
};
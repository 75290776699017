import { get } from './generic.api';
import format from 'date-fns/format';

export const fetchReportClear = async (clientId, startDate, endDate, categoryId, clear = false, Validationkeyword, subject, swot, qualitative, mention) => {
    try {
        const formattedStartDate = format(new Date(startDate), 'dd/MM/yyyy');
        const formattedEndDate = format(new Date(endDate), 'dd/MM/yyyy');
        // const categoriesQuery = selectedCategories 
        // ? selectedCategories.map(cat => `categories=${cat.value}`).join('&')
        // : '';

        // const params = {
        //     client: clientId,
        //     date_start: formattedStartDate,
        //     date_end: formattedEndDate,
        //     text: Validationkeyword,
        //     subject: subject,
        //     swot: swot,
        //     qualitative: qualitative,
        //     mention: mention,
            
        // };

        // const queryString = Object.entries(params)
        // .filter(([_, value]) => value)
        // .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
        // .join('&');

        // const fullQuery = `${queryString}&${categoriesQuery}&${clear ? 'clear=true' : ''}`;
        
        // const response = await get(`/clipping/report/?${fullQuery}`);

        const params = {
            client: clientId,
            date_start: formattedStartDate,
            date_end: formattedEndDate,
            text: Validationkeyword,
            subject: subject,
            swot: swot,
            qualitative: qualitative,
            mention: mention,
        };
        
        if (categoryId) params.category = categoryId;
        if (clear) params.clear = true;

        const response = await get('/clipping/report/', params);
        return response;
    } catch (error) {
        console.error('Erro ao buscar relatório:', error.response);
        throw error;
    }
};
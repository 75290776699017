// TelaVisualizacao.js

import React, { useState, useEffect, useRef } from 'react';
import './TelaVisualizacao.css';
import { useParams, Link, useHistory, useLocation } from 'react-router-dom'; 
import Menu from '../../components/menu/Menu';
import NavBar from '../../components/navbar/NavBar';
import Avaliacao from '../../components/Avaliacao/Avaliacao';
import VideoPlayer from '../../components/VideoPlayer/VideoPlayer';
import { fetchClippingBy_Id } from '../../services/api/fetchClipping';
import { fetchGroupersData } from '../../services/api/fetchGrouper';
import { deleteClipping } from '../../services/api/fetchClipping';
import { fetchUser } from '../../services/api/fetchUser';
import { FaDownload } from 'react-icons/fa';
import MP4Player from '../../components/VideoPlayer/MP4Player';
import { FaShareAlt, FaFileAlt, FaLink, FaFile, FaImage } from 'react-icons/fa';
import { deleteEvaluation } from '../../services/api/fetchClipping';
import ReactLoading from 'react-loading';

function TelaVisualizacao({ clippingId: propClippingId, clientId: propClientId, isModal, onLoadingComplete, onDeleteComplete  }) {
  const [showRatingModal, setShowRatingModal] = useState(false);
  const [isAvaliado, setIsAvaliado] = useState(false);
  const { clippingId: paramsClippingId, clientId: paramsClientId } = useParams();
  const clippingId = propClippingId || paramsClippingId;
  const clientId = propClientId || paramsClientId;
  const [clipping, setClipping] = useState(null);
  const [userProfile, setUserProfile] = useState(null);
  const [activeThumbnailIndex, setActiveThumbnailIndex] = useState(0);
  const [showA4Modal, setShowA4Modal] = useState(false);
  const [currentThumbnailIndex, setCurrentThumbnailIndex] = useState(0);
  const [isZoomed, setIsZoomed] = useState(false);
  const [zoomOrigin, setZoomOrigin] = useState({ x: 0, y: 0 });
  const imageRef = useRef(null);
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isEvaluated, setIsEvaluated] = useState(false);
  const location = useLocation();
  const previousPath = location.state?.previousPath || `/${clientId}/telaNoticias`;
  const [loadingDownload, setLoadingDownload] = useState(false);
  const [videoCardStyle, setVideoCardStyle] = useState({});
  const [linkCopied, setLinkCopied] = useState(false);
  const [isDownloadLoading, setIsDownloadLoading] = useState(false);
  const [isMenuExpanded, setIsMenuExpanded] = useState(false);
  const [showResume, setShowResume] = useState(true);
  const [showOriginalPagesModal, setShowOriginalPagesModal] = useState(false);
  const [currentOriginalPageIndex, setCurrentOriginalPageIndex] = useState(0);
  const [isOriginalPagesZoomed, setIsOriginalPagesZoomed] = useState(false);
  const [originalPagesZoomOrigin, setOriginalPagesZoomOrigin] = useState({ x: 0, y: 0 });
  const [currentFileIndex, setCurrentFileIndex] = useState(0);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);


  const checkIfClippingIsEvaluated = async () => {
    try {
      const evaluationData = await fetchGroupersData(clippingId);
      const evaluated = evaluationData.some(item => item.clipping === parseInt(clippingId));
      setIsEvaluated(evaluated);
    } catch (error) {
      console.error('Erro ao verificar se o clipping foi avaliado:', error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); // Adicionar loading state
      try {
        const foundClipping = await fetchClippingBy_Id(clippingId);
        setClipping(foundClipping); // Definir dados do clipping
        const user = await fetchUser(clientId); 
        setUserProfile(user.profile);
        await checkIfClippingIsEvaluated(); 
        if (onLoadingComplete) onLoadingComplete(); 
      } catch (error) {
        console.error("Error fetching clipping data:", error);
      } finally {
        setLoading(false); // Finalizar loading state
      }
    };
  
    fetchData();
  }, [clientId, clippingId, onLoadingComplete]);

  useEffect(() => {
    if (clipping && clipping.thumbnails && clipping.thumbnails.length > 0) {
      const interval = setInterval(() => {
        setActiveThumbnailIndex(prevIndex => (prevIndex + 1) % clipping.thumbnails.length);
      }, 3000);
      return () => clearInterval(interval);
    }
  }, [clipping]);

  useEffect(() => {
    if (clipping && clipping.source_detail && 
        (clipping.source_detail.source_type === 'tv' || 
         clipping.source_detail.source_type === 'radio') && 
        clipping.files && clipping.files.length > 0) {
      
      const interval = setInterval(() => {
        setCurrentImageIndex((prevIndex) =>
          (prevIndex + 1) % clipping.files.length
        );
      }, 10000);
  
      return () => clearInterval(interval);
    }
  }, [clipping]);

  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) =>
      (prevIndex + 1) % clipping.files.length
    );
  };

  const handlePrevImage = () => {
    setCurrentImageIndex((prevIndex) =>
      (prevIndex - 1 + clipping.files.length) % clipping.files.length
    );
  };

  const highlightKeywordsInHTML = (html, keywords) => {
    if (!keywords) return html;
    const keywordArray = keywords.split(',').map(k => k.trim());
    const regex = new RegExp(`(^|\\s|[^a-zA-Z])(${keywordArray.join('|')})(\\s|[^a-zA-Z]|$)`, 'gi');
    return html.replace(regex, '$1<span class="highlight">$2</span>$3');
  };
  

  if (loading) {
    return null;
  }

  if (!clipping) {
    return <p>Clipping não encontrado.</p>;
  }

  const isRestrictedType = clipping.source_detail.source_type === 'tv' || clipping.source_detail.source_type === 'radio';
  const hasA4Image = (clipping.source_detail.source_type === 'blog' || clipping.source_detail.source_type === 'site') 
    ? clipping.files && clipping.files.length > 0 
    : (clipping.source_detail.source_type !== 'tv' && clipping.source_detail.source_type !== 'radio');

    const baseUrl = process.env.REACT_APP_API_URL.replace(/\/$/, '');
    const url_video = clipping.stream_url 
    ? `${baseUrl}${clipping.stream_url}`
    : clipping.files && clipping.files.length > 0 
      ? clipping.files[0].content_file
      : null;

  let videoTitleContent;
  if (clipping.source_detail.source_type === 'radio') {
    videoTitleContent = 'Áudio do Clipping:';
  } else if (clipping.source_detail.source_type === 'tv') {
    videoTitleContent = 'Vídeo do Clipping:';
  } else {
    videoTitleContent = 'Arquivo do Clipping:';
  }

  const handleShareClick = () => {
    const shareUrl = `${process.env.REACT_APP_CLIENT_URL}visualizarNoticiaNewsletter/${clippingId}`;
    navigator.clipboard.writeText(shareUrl).then(() => {
      setLinkCopied(true);
      setTimeout(() => {
        setLinkCopied(false);
      }, 2000); // O texto volta ao normal após 2 segundos
    }).catch(err => {
      console.error('Erro ao copiar o link: ', err);
    });
  };

  const handleNextFile = () => {
    if (clipping.files && clipping.files.length > 1) {
      setCurrentFileIndex((prevIndex) => (prevIndex + 1) % clipping.files.length);
      setIsZoomed(false);
      setZoomOrigin({ x: 0, y: 0 });
    }
  };
  
  const handlePrevFile = () => {
    if (clipping.files && clipping.files.length > 1) {
      setCurrentFileIndex((prevIndex) => (prevIndex - 1 + clipping.files.length) % clipping.files.length);
      setIsZoomed(false);
      setZoomOrigin({ x: 0, y: 0 });
    }
  };
  

  const handleOpenRatingModal = () => {
    setShowRatingModal(true);
  };

  const handleCloseRatingModal = () => {
    setShowRatingModal(false);
  };

  const handleAvaliar = () => {
    setIsAvaliado(true);
  };

  const handleEditClick = () => {
    localStorage.setItem('clippingId', clippingId);
  };

  const handleOpenA4Modal = () => {
    if ((clipping.source_detail.source_type === 'blog' || clipping.source_detail.source_type === 'site') && clipping.files && clipping.files.length > 0) {
      setCurrentThumbnailIndex(0);
      setIsZoomed(false);
      setZoomOrigin({ x: 0, y: 0 });
    }
    setShowA4Modal(true);
  };

  const handleOpenFilesModal = () => {
    setCurrentThumbnailIndex(0);
    setIsZoomed(false);
    setZoomOrigin({ x: 0, y: 0 });
    setShowA4Modal(true); // Reutilizando o modal A4
  };

  const handleCloseA4Modal = () => {
    setShowA4Modal(false);
  };

  const handleNextThumbnail = () => {
    setCurrentThumbnailIndex((prevIndex) => (prevIndex + 1) % clipping.thumbnails.length);
    setIsZoomed(false);
    setZoomOrigin({ x: 0, y: 0 });
  };

  const handlePrevThumbnail = () => {
    setCurrentThumbnailIndex((prevIndex) => (prevIndex - 1 + clipping.thumbnails.length) % clipping.thumbnails.length);
    setIsZoomed(false);
    setZoomOrigin({ x: 0, y: 0 });
  };

  const handleImageClick = (e) => {
    const { offsetX, offsetY, target } = e.nativeEvent;
    const { width, height } = target;
    const zoomX = ((offsetX / width) * 100).toFixed(2);
    const zoomY = ((offsetY / height) * 100).toFixed(2);

    setZoomOrigin({ x: zoomX, y: zoomY });
    setIsZoomed((prevZoom) => !prevZoom);
  };

  const clippingCardStyle = isRestrictedType ? { width: '530px' } : {};

  const handleDeleteClipping = async () => {
    setIsLoading(true);
    console.log("Deleting clipping with clientId:", clientId, "and clippingId:", clippingId);
    try {
      await deleteClipping(clippingId);
      setSuccessMessage('Clipping excluído com sucesso!');
      setErrorMessage('');
  
      if (isModal) {
        if (onDeleteComplete) {
          onDeleteComplete();
        }
      } else {
        setTimeout(() => {
          setSuccessMessage('');
          history.push(`/${clientId}/telaNoticias`);
        }, 5000);
      }
    } catch (error) {
      console.error('Erro ao excluir clipping:', error);
      setErrorMessage('Erro ao excluir clipping. Por favor, tente novamente.');
      setSuccessMessage('');
      setTimeout(() => setErrorMessage(''), 5000);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddFilter = (filterData) => {
    console.log('Dados do filtro recebidos:', filterData);
  };

  const handleSuccessMessage = (message) => {
    setSuccessMessage(message);
    setTimeout(() => {
      setSuccessMessage('');
    }, 5000);
  };

  const handleErrorMessage = (message) => {
    setErrorMessage(message);
    setTimeout(() => {
      setErrorMessage('');
    }, 5000);
  };

  // Adicione este manipulador de clique ao overlay do modal de avaliação
  const handleRatingOverlayClick = (e) => {
    if (e.target.className === 'modal-overlay-visualizacao') {
      handleCloseRatingModal();
    }
  };

// Realizar download do arquivo de TV e Radio
const handleDownload = async (url) => {
  setIsDownloadLoading(true);
  setSuccessMessage('');
  setErrorMessage('');
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error('Erro ao baixar o arquivo');
    }
    const blob = await response.blob();
    const link = document.createElement('a');
    const downloadUrl = window.URL.createObjectURL(blob);
    const filename = `clipping${getFileExtension(url)}`; // Nome do arquivo com a extensão correta
    link.href = downloadUrl;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
    window.URL.revokeObjectURL(downloadUrl);
    setSuccessMessage('Download concluído com sucesso!');
    setTimeout(() => setSuccessMessage(''), 5000);
  } catch (error) {
    console.error('Erro ao baixar o arquivo:', error);
    setErrorMessage('Erro ao baixar o arquivo. Por favor, tente novamente.');
    setTimeout(() => setErrorMessage(''), 5000);
  } finally {
    setIsDownloadLoading(false);
  }
};

const getFileExtension = (url) => {
  const extension = url.split('.').pop().split(/\#|\?/)[0];
  return extension ? `.${extension}` : '';
};


  const adjustImageSizesInHTML = (html) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    
    doc.querySelectorAll('img').forEach(img => {
      img.style.width = '500px';
      img.style.height = '300px';
    });
    
    return doc.body.innerHTML;
  };

  const highlightedContent = highlightKeywordsInHTML(clipping.content, clipping.keyword_used);
  const adjustedHighlightedContent = adjustImageSizesInHTML(highlightedContent);
  const titleWithSource = `${clipping.title} (Fonte: ${clipping.source_detail.name})`;
  const highlightedTitleWithSource = highlightKeywordsInHTML(titleWithSource, clipping.keyword_used);

  const showMarketingValue = (clipping.marketing_value);
  const showOccupiedSpace = (clipping.occupied_space);
  const showProgramValue = (clipping.program && clipping.program.name && (clipping.source_detail.source_type === 'tv' || clipping.source_detail.source_type === 'radio'));
  const ShowMediaType = (clipping.media_content_type);

  let startSeconds, endSeconds;
  if (clipping.source_detail && (clipping.source_detail.source_type === 'tv' || clipping.source_detail.source_type === 'radio')) {
    startSeconds = clipping.audiovisual_clip ? clipping.audiovisual_clip.start_as_total_seconds : null;
    endSeconds = clipping.audiovisual_clip ? clipping.audiovisual_clip.end_as_total_seconds : null;
  }

  const handleToggleMenu = (expanded) => {
    setIsMenuExpanded(expanded);
  };

  const toggleResumeContent = () => {
    setShowResume((prevShowResume) => !prevShowResume);
  };

  const handleOpenOriginalPagesModal = () => {
    setCurrentOriginalPageIndex(0);
    setShowOriginalPagesModal(true);
  };
  
  const handleCloseOriginalPagesModal = () => {
    setShowOriginalPagesModal(false);
  };
  
  const handleNextOriginalPage = () => {
    setCurrentOriginalPageIndex((prevIndex) => (prevIndex + 1) % clipping.original_pages.length);
  };
  
  const handlePrevOriginalPage = () => {
    setCurrentOriginalPageIndex((prevIndex) => (prevIndex - 1 + clipping.original_pages.length) % clipping.original_pages.length);
  };

  const handleOriginalPageImageClick = (e) => {
    const { offsetX, offsetY, target } = e.nativeEvent;
    const { width, height } = target;
    const zoomX = ((offsetX / width) * 100).toFixed(2);
    const zoomY = ((offsetY / height) * 100).toFixed(2);
  
    setOriginalPagesZoomOrigin({ x: zoomX, y: zoomY });
    setIsOriginalPagesZoomed((prevZoom) => !prevZoom);
  };

  const handleClearEvaluation = async () => {
    setIsLoading(true);
    try {
      await deleteEvaluation(clippingId);
      setIsEvaluated(false);
      setSuccessMessage('Avaliação limpa com sucesso!');
      setTimeout(() => setSuccessMessage(''), 5000);
    } catch (error) {
      console.error('Erro ao limpar avaliação:', error);
      setErrorMessage('Erro ao limpar avaliação. Por favor, tente novamente.');
      setTimeout(() => setErrorMessage(''), 5000);
    } finally {
      setIsLoading(false);
    }
  };
  

  return (
    <div className={`App ${isMenuExpanded ? 'menu-expanded' : 'menu-collapsed'}`}>
      {!isModal && (
        <div className="menu">
          <Menu onToggleMenu={handleToggleMenu} />
        </div>
      )}
      {!isModal && (
        <div className="navbar">
          <NavBar />
        </div>
      )}
      <div
        className="clipping-container"
        style={isModal ? { 
          backgroundColor: '#fff',
          display: 'flex',
          flexDirection: 'column',
          marginTop: '60px',
          marginRight: '95px',
          marginLeft: '50px',
          marginBottom: '40px'
        } : {}}
      >
      <header className="clipping-header">
          <h1 className="clipping-title">
          {!isModal && (
            <Link 
              to={{ 
                pathname: previousPath, 
                state: { 
                  previousPath: location.pathname, 
                  activeTab: 'tab1' 
                } 
              }} 
              className="home-link"
            >
              <img
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/d5929b77c00679695d19f92f7e32c26c91de2a2ee22bd02668e17a8ec96b8ff1?apiKey=c7cb0dec5bf7436eb48ab9b2fb417d9d"
                alt=""
                className="clipping-icon"
              />
            </Link>
          )}
          </h1>
          <div className="clipping-header-buttons-public">
                {userProfile !== 'cliente' && (
                  <>
                  {isEvaluated && (
                    <button 
                      className="clear-evaluation-button" 
                      onClick={handleClearEvaluation}
                    >
                      Limpar Avaliação
                    </button>
                  )}
                  <button className={`evaluate-button ${isEvaluated ? 'avaliado' : ''}`} onClick={() => { handleAvaliar(); handleOpenRatingModal(); }}>
                    {isEvaluated ? 'Avaliado' : 'Avaliar'}
                  </button>
                </>
              )}
              <button className="share-button" onClick={handleShareClick}>
                <FaShareAlt />
                <span className="share-text">{linkCopied ? 'Link Copiado!' : 'Compartilhar'}</span>
              </button>
              {clipping.source_detail.source_type !== 'tv' && clipping.source_detail.source_type !== 'radio' && (
                <>
                  <button 
                    className={`clipping-action-button ${hasA4Image ? '' : 'disabled'}`} 
                    onClick={hasA4Image ? handleOpenA4Modal : undefined}
                    disabled={!hasA4Image}
                    style={{ cursor: hasA4Image ? 'pointer' : 'not-allowed' }}
                  >
                    <FaFileAlt />
                    <span className="a4-text">Visualizar A4</span>
                  </button>
                  {clipping.source_detail.source_type === 'impresso' && clipping.original_pages && clipping.original_pages.length > 0 && (
                    <button
                      className="clipping-action-button"
                      onClick={handleOpenOriginalPagesModal}
                    >
                      <FaFile  />
                      <span className="a4-text">Página Original</span>
                    </button>
                  )}
                  {clipping.url && (
                    <a 
                      href={clipping.url} 
                      className={`clipping-action-link ${clipping.url ? '' : 'disabled'}`} 
                      target="_blank" 
                      rel="noopener noreferrer"
                      style={{ cursor: clipping.url ? 'pointer' : 'not-allowed', pointerEvents: clipping.url ? 'auto' : 'none' }}
                    >
                      <FaLink />
                      <span className="link-text">Link da notícia</span>
                    </a>
                  )}
                </>
              )}
                {(clipping.source_detail.source_type === 'tv' || clipping.source_detail.source_type === 'radio') && 
                  clipping.files && clipping.files.length > 0 && (
                      <button 
                        className="clipping-action-button" 
                        onClick={handleOpenFilesModal}
                        style={{ cursor: 'pointer' }}
                      >
                        <FaImage />
                        <span className="a4-text">Visualizar Imagens</span>
                      </button>
                )}
            </div>
      </header>
        {successMessage && <div className="success-message">{successMessage}</div>}
        {errorMessage && <div className="error-message">{errorMessage}</div>}

        {clipping.source_detail.source_type === 'tv' || clipping.source_detail.source_type === 'radio' ? (
        <div className="clipping-additional-info">
          <div className="clipping-title-container">
            <h2 className="clipping-additional-title">{clipping.title}</h2>
            <hr className="clipping-additional-divider" />
          </div>
          <div className="clipping-details-container">
            <p>Tipo do clipping: {clipping.source_detail.source_type}</p>
            {ShowMediaType && <p>Tipo de Notícia: {clipping.media_content_type}</p>}
            <p>Data da publicação: {clipping.publish_date}</p>
            <p>Fonte: {clipping.source_detail.name}</p>
            <p>Categoria: {clipping.categories.length > 0 ? clipping.categories.map(category => category.name).join(', ') : 'N/A'}</p>
            {showProgramValue && <p>Programa: {clipping.program.name}</p>}
            {showMarketingValue && <p>Valoração: R$ {clipping.marketing_value}</p>}
            {showOccupiedSpace && (
              <p>
                {clipping.source_detail.source_type === 'tv' || clipping.source_detail.source_type === 'radio'
                  ? 'Minutagem'
                  : 'Centimetragem'}: {clipping.occupied_space}
              </p>
            )}
          </div>
        </div>
      ) : null}

        {clipping.stream_url && (
          <div className="clipping-content">
            <div className="clipping-row">
              {clipping.source_detail.source_type !== 'site' && 
                clipping.source_detail.source_type !== 'blog' && 
              <aside className="clipping-video">
                {(clipping.source_detail.source_type === 'tv' || clipping.source_detail.source_type === 'radio') && (
                <div className="clipping-video-card" style={videoCardStyle}>
                <div className="clipping-video-header">
                  <h3 className="clipping-video-title">{videoTitleContent}</h3>
                  {(clipping.source_detail.source_type === 'tv' || clipping.source_detail.source_type === 'radio') && clipping.document && (
                    <button onClick={() => handleDownload(clipping.document)} className="download-button-visualizacao" disabled={isDownloadLoading}>
                      {isDownloadLoading ? (
                        <span className="loading-spinner"></span>
                      ) : (
                        <FaDownload />
                      )}
                    </button>
                  )}
                </div>
                {isRestrictedType ? (
                      clipping.stream_url ? (
                        <VideoPlayer
                          link={url_video}
                          startSeconds={startSeconds !== undefined ? startSeconds : null}
                          endSeconds={endSeconds !== undefined ? endSeconds : null}
                        />
                      ) : (
                        clipping.files && clipping.files.length > 0 && clipping.files[0].content_file && (
                          <MP4Player
                            link={url_video}
                            startSeconds={startSeconds !== undefined ? startSeconds : null}
                            endSeconds={endSeconds !== undefined ? endSeconds : null}
                          />
                        )
                      )
                    ): (
                    /*clipping.thumbnails && clipping.thumbnails.length > 0 ? (
                      <div className="clipping-thumbnails-carousel">
                        {clipping.thumbnails.map((thumbnail, index) => (
                          <img 
                            key={index} 
                            src={thumbnail} 
                            alt={`Thumbnail ${index + 1}`} 
                            className={`clipping-thumbnail ${index === activeThumbnailIndex ? 'active' : ''}`} 
                          />
                        ))}
                      </div>
                    ) :*/ ''
                    /*(
                      (clipping.source_detail.source_type === 'site' || clipping.source_detail.source_type === 'blog') && (
                        <iframe
                          src={clipping.url}
                          title="Pré-visualização do site"
                          width="100%"
                          height="400px"
                          frameBorder="0"
                          style={{ border: '1px solid #ccc' }}
                        ></iframe>
                      )
                    )*/
                  )}
                </div>
                )}
              </aside>}
            </div>
          </div>
        )}

        {clipping && clipping.files && clipping.files.length > 0 && (clipping.source_detail.source_type === 'tv' || clipping.source_detail.source_type === 'radio') && (
          <div className="clipping-content-carrosel-visualization-screen">
            <div className="clipping-row-carrosel-visualization-screen">
                <aside className="clipping-images-carousel">

                  <div className="carousel-container">
                    <button className='prev-button-visualization-screen' onClick={handlePrevImage}>&lt;</button>
                    
                    <img
                      src={clipping.files[currentImageIndex].content_file}
                      alt={`Imagem ${currentImageIndex + 1}`}
                      className="carousel-image"
                    />

                    <button className='prev-button-visualization-screen' onClick={handleNextImage}>&gt;</button>
                  </div>
                </aside>
            </div>
          </div>
        )}

        <article className="clipping-description">
        <div className="clipping-content-toggle">
          {clipping.source_detail.source_type === 'tv' || clipping.source_detail.source_type === 'radio' ? (
            <>
              <button 
                onClick={() => setShowResume(true)} 
                className={showResume ? 'selected' : ''}
              >
                Resumo
              </button>
              <button 
                onClick={() => setShowResume(false)} 
                className={!showResume ? 'selected' : ''}
              >
                Transcrição
              </button>
            </>
          ) : null}
        </div>
          <div className="clipping-description-content-wrapper">
            {clipping.source_detail.source_type === 'radio' || clipping.source_detail.source_type === 'tv' ? (
              <div className="clipping-description-text">
                {clipping.source_detail.source_type === 'tv' || clipping.source_detail.source_type === 'radio' ? (
                  showResume ? (
                    <p>{clipping.resume}</p>
                  ) : (
                    <div dangerouslySetInnerHTML={{ __html: adjustedHighlightedContent }}></div>
                  )
                ) : (
                  <div dangerouslySetInnerHTML={{ __html: adjustedHighlightedContent }}></div>
                )}
              </div>
            ) : (
              <div 
                className="clipping-text-content" 
                style={{ width: clipping.source_detail.source_type === 'impresso' ? '60%' : '100%' }}
              >
                <h2 className="clipping-description-title" dangerouslySetInnerHTML={{ __html: highlightedTitleWithSource }}></h2>
                <p className="clipping-detail-inline">
                  Tipo do clipping: {clipping.source_detail.source_type} <br  />
                  {clipping.media_content_type && (
                    <p style={{ margin: 0 }}>Tipo de Notícia: {clipping.media_content_type}</p>
                  )}
                  Data da publicação: {clipping.publish_date} <br  />
                  Categoria: {clipping.categories.length > 0 ? clipping.categories.map(category => category.name).join(', ') : 'N/A'} <br  />
                  Fonte: {clipping.source_detail.name} 
                  {showProgramValue && ` Programa: ${clipping.program.name} `} <br  />
                  {showMarketingValue && ` Valoração: R$ ${clipping.marketing_value} `} <br  />
                  {showOccupiedSpace && `${clipping.source_detail.source_type === 'tv' || clipping.source_detail.source_type === 'radio' ? ' Minutagem' : ' Centimetragem'}: ${clipping.occupied_space}`}
                </p>
                <hr className="clipping-description-divider" />
                <div className="clipping-description-text" dangerouslySetInnerHTML={{ __html: adjustedHighlightedContent }}></div>
              </div>
            )}

            {clipping.source_detail.source_type === 'impresso' && clipping.thumbnails && clipping.thumbnails.length > 0 && (
              <div className="clipping-thumbnails-carousel">
                {clipping.thumbnails.map((thumbnail, index) => (
                  <img 
                    key={index} 
                    src={thumbnail} 
                    alt={`Thumbnail ${index + 1}`} 
                    className={`clipping-thumbnail ${index === activeThumbnailIndex ? 'active' : ''}`} 
                  />
                ))}
              </div>
            )}
          </div>
        </article>


        <footer className="clipping-footer">
        {userProfile !== 'cliente' && (
          <>
            <a className="remove-clipping-button" onClick={handleDeleteClipping}>Remover clipping</a>
            <Link to={'/' + clientId + "/editarClipping"} className="edit-clipping-button" onClick={handleEditClick}>
              Editar notícia
            </Link>
          </>
          )}
        </footer>

        {showRatingModal && (
          <div className="modal-overlay-visualizacao" onClick={handleRatingOverlayClick}>
            <div className="modal-content-visualizacao">
              <Avaliacao 
                onClose={handleCloseRatingModal} 
                clientId={clientId} 
                clippingId={clippingId}
                onAddFilter={(filterData) => {
                  handleAddFilter(filterData);
                  handleSuccessMessage('Avaliação realizada com sucesso!');
                  setIsEvaluated(true);
                }} 
                loadExistingData={true} 
              />
            </div>
          </div>
        )}

        {showOriginalPagesModal && (
          <div className="modal-overlay">
            <div className="modal-content">
              <button className="close-modal" onClick={handleCloseOriginalPagesModal}>X</button>
              <div className="thumbnail-navigation">
                <button onClick={handlePrevOriginalPage} disabled={clipping.original_pages.length <= 1}>&#8592;</button>
                <button onClick={handleNextOriginalPage} disabled={clipping.original_pages.length <= 1}>&#8594;</button>
              </div>
              <div className="thumbnail-image-container" ref={imageRef}>
                <img 
                  src={clipping.original_pages[currentOriginalPageIndex]} 
                  alt={`Original Page ${currentOriginalPageIndex + 1}`} 
                  className="thumbnail-image"
                  onClick={handleOriginalPageImageClick}
                  style={{ 
                    transformOrigin: `${originalPagesZoomOrigin.x}% ${originalPagesZoomOrigin.y}%`,
                    transform: `scale(${isOriginalPagesZoomed ? 2 : 1})`
                  }}
                />
              </div>
            </div>
          </div>
        )}

        {showA4Modal && (
          <div className="modal-overlay">
            <div className="modal-content">
              <button className="close-modal" onClick={handleCloseA4Modal}>X</button>
                <div className="thumbnail-navigation">
                  {clipping.source_detail.source_type === 'impresso' ? (
                    <>
                      <button 
                        onClick={handlePrevThumbnail} 
                        disabled={clipping.thumbnails.length <= 1}
                      >
                        &#8592;
                      </button>
                      <button 
                        onClick={handleNextThumbnail} 
                        disabled={clipping.thumbnails.length <= 1}
                      >
                        &#8594;
                      </button>
                    </>
                  ) : (
                    <>
                      <button 
                        onClick={handlePrevFile} 
                        disabled={clipping.files.length <= 1}
                      >
                        &#8592;
                      </button>
                      <button 
                        onClick={handleNextFile} 
                        disabled={clipping.files.length <= 1}
                      >
                        &#8594;
                      </button>
                    </>
                  )}
                </div>
              <div 
                className="thumbnail-image-container"
                ref={imageRef}
              >
                {clipping.source_detail.source_type === 'blog' || clipping.source_detail.source_type === 'site' || clipping.source_detail.source_type === 'tv' || clipping.source_detail.source_type === 'radio'? (
                  <img 
                  src={clipping.files && clipping.files[currentFileIndex] ? clipping.files[currentFileIndex].content_file : ''} 
                  alt={`Content File ${currentFileIndex + 1}`} 
                    className="thumbnail-image" 
                    onClick={handleImageClick}
                    style={{ 
                      transformOrigin: `${zoomOrigin.x}% ${zoomOrigin.y}%`,
                      transform: `scale(${isZoomed ? 2 : 1})`
                    }}
                  />
                ) : (
                  <img 
                    src={clipping.thumbnails[currentThumbnailIndex]} 
                    alt={`Thumbnail ${currentThumbnailIndex + 1}`} 
                    className="thumbnail-image" 
                    onClick={handleImageClick}
                    style={{ 
                      transformOrigin: `${zoomOrigin.x}% ${zoomOrigin.y}%`,
                      transform: `scale(${isZoomed ? 2 : 1})`
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        )}
      </div>

      {isLoading && (
        <div className={isLoading ? 'loading-overlay active' : 'loading-overlay'}>
          <ReactLoading type={'spin'} color={'#121926'} height={80} width={80} />
        </div>
      )}
    </div>
  );
}

export default TelaVisualizacao;

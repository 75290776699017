import React, { useState } from 'react';
import './SaveSelectionModal.css';
import { createClippingSelection } from '../../services/api/fetchClippingsSelection';
import ReactLoading from 'react-loading';

const SaveSelectionModal = ({ selectedIds, clientId, onClose, onSave, onSuccessMessage, onErrorMessage }) => {
  const [selectionName, setSelectionName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState("");

  const handleSaveSelection = async () => {

    if (!selectionName || selectionName.trim() === '') {
      setErrorMessage(`O nome da seleção não pode estar vazio.`);
      setTimeout(() => setErrorMessage(''), 5000); 
      return;
    }

    if (selectedIds.length > 500) {
      setErrorMessage(`A seleção não pode ter mais de 500 noticias.`);
      setTimeout(() => setErrorMessage(''), 5000);
      return;
    }

    const payload = {
      name: selectionName,
      client: clientId,
      clippings: selectedIds,
    };

    setIsLoading(true);

    try {
      const response = await createClippingSelection(payload);
      onSuccessMessage('Seleção salva com sucesso!');
      console.log('Seleção salva com sucesso:', response);
      onSave(selectionName, selectedIds, clientId);
      onClose();
    } catch (error) {

      if (error.response && error.response.data) {
        const clientErrorMessage = error.response.data.client?.[0];
        if (clientErrorMessage) {
          setErrorMessage(`Limte de 12 seleções atingido.`);
          setTimeout(() => setErrorMessage(''), 5000); 
          return;
        }

        const nameErrorMessage = error.response.data.name?.[0];
        if (nameErrorMessage) {
          setErrorMessage(`O nome da seleção não pode ter mais de 50 caracteres.`);
          setTimeout(() => setErrorMessage(''), 5000);
          return;
        }
      }
      setErrorMessage(`Erro ao salvar a seleção. Tente novamente.`);
      setTimeout(() => setErrorMessage(''), 5000); 
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="modal-overlay-save-selection">
      {isLoading && (
        <div className={isLoading ? 'loading-overlay active' : 'loading-overlay'}>
          <ReactLoading type={'spin'} color={'#121926'} height={80} width={80} />
        </div>
      )}
      {successMessage && <div className="success-message">{successMessage}</div>}
      {errorMessage && <div className="error-message">{errorMessage}</div>}
      <div className="modal-content-save-selection">
        <h2 className='title-save-selection'>Salvar Seleção</h2>

        <div className="container-save-selection">
            <div className="filter-save-selection">
                <div className="filter-save-selection-text">Nome da Seleção:</div>
                <input
                    type="text"
                    id="selection-name"
                    value={selectionName}
                    onChange={(e) => setSelectionName(e.target.value)}
                    placeholder="Digite o nome da seleção"
                    className="input-save-selection"
                />
            </div>
        </div>

        <div className="button-group">
          <button onClick={onClose} className="cancel-button">Cancelar</button>
          <button onClick={handleSaveSelection} className="save-button-save-selection">Salvar</button>
        </div>

      </div>
    </div>
  );
};

export default SaveSelectionModal;

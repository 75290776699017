import { post, del, get, patch } from './generic.api';

export const createClipping = async (formData) => {
    try {
      const response = await post('/clipping/create_clipping/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      return response;
    } catch (error) {
      console.error('Erro ao enviar dados para a API:', error.response);
      throw error;
    }
  };

  export const deleteClipping = async (clippingId) => {
    try {
      const response = await del(`/clipping/${clippingId}/`);
      return response;
    } catch (error) {
      console.error('Erro ao excluir clipping:', error.response);
      throw error;
    }
  };

  export const fetchAllInfo = async (clientId, groupers) => {
    try {
      const response = await get('/clipping/', {  client: clientId, groupers: JSON.stringify(groupers) }); // Adiciona clientId como parâmetro na requisição
      const clippings = response.results;
  
      const states = new Set();
      const cities = [];
      const vehicles = [];
      const categories = [];
  
      clippings.forEach(clipping => {
        const city = clipping.source_detail?.city;
        const state = city?.state;
        const vehicle = clipping.source_detail?.name;
        const categoryList = clipping.categories;
  
        if (city && state) {
          states.add(state);
          cities.push({
            id: city.id,
            name: city.name,
            state: state
          });
        }
  
        if (vehicle) {
          vehicles.push({
            id: clipping.source_detail.id,
            name: clipping.source_detail.name,
          });
        }
  
        if (categoryList && categoryList.length > 0) {
          categoryList.forEach(category => {
            let teste = false;
            categories.forEach(cat => {
              if (cat.id === category.id) {
                teste = true;
              }
            });
            if (!teste) {
              categories.push({
                id: category.id,
                name: category.name,
              });
            }
          });
        }
      });
  
      return { 
        states: Array.from(states), 
        cities, 
        vehicles: Array.from(vehicles), 
        categories: Array.from(categories) 
      };
    } catch (error) {
      console.error('Erro ao buscar estados, cidades, veículos e categorias:', error);
      throw error;
    }
  };

  export const fetchClippingBy_Id = async (clippingId) => {
    const response = await get(`/clipping/${clippingId}`);
    return response;
  };

  export const fetchClippingById = async (clippingId) => {
    try {
      const response = await get(`/clipping/${clippingId}/`);
      return response;
    } catch (error) {
      console.error('Erro ao buscar dados do clipping:', error.response);
      throw error;
    }
  };

  export const fetchClippings = async (clientId, searchTerm, sourceType, city, state, source, categories, startDate, endDate, author, groupers, hasEvaluation, page = 1, page_size = 50) => {
    const params = {
      client: clientId,
      has_evaluation: hasEvaluation !== null ? hasEvaluation : '',
      text: searchTerm,
      source_type: sourceType,
      city: city,
      state: state,
      sources: source,
      categories: categories,
      date_start: startDate,
      date_end: endDate,
      authors: author,
      groupers: JSON.stringify(groupers), 
      page,
      page_size,
    };
  
    // Remove parâmetros vazios
    const filteredParams = Object.fromEntries(Object.entries(params).filter(([_, v]) => v !== ""));
  
    try {
      const response = await get('/clipping/',  filteredParams );
      return response;
    } catch (error) {
      console.error('Erro ao buscar clippings:', error.response);
      throw error;
    }
  };

  export const fetchClippingIds = async (clientId, searchTerm, sourceType, city, state, source, categories, startDate, endDate, author, groupers, hasEvaluation) => {
    const params = {
      client: clientId,
      has_evaluation: hasEvaluation !== null ? hasEvaluation : '',
      text: searchTerm,
      source_type: sourceType,
      city: city,
      state: state,
      source: source,
      categories: categories,
      date_start: startDate,
      date_end: endDate,
      authors: author,
      groupers: JSON.stringify(groupers), 
    };
  
    const filteredParams = Object.fromEntries(Object.entries(params).filter(([_, v]) => v !== ""));
  
    try {
      const response = await get('/clipping/list_id/', filteredParams);
      return response;
    } catch (error) {
      console.error('Erro ao buscar IDs dos clippings:', error.response);
      throw error;
    }
  };

  export const fetchClippingsByDate = async (clientId, dateStart, dateEnd, page = 1, page_size = 50) => {
    try {
      const response = await get('/clipping/', {
          client: clientId,
          date_start: dateStart, 
          date_end: dateEnd,
          page,
          page_size
      });
      return {
        results: response.results,
        count: response.count,
      };
    } catch (error) {
      console.error('Erro ao buscar clippings por data:', error.response);
      throw error;
    }
  };

  export const fetchReport = async (clientId, startDate, endDate, categoryId, Validationkeyword, subject, swot, qualitative, mention) => {
    try {
        const formatDate = (date) => {
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const year = date.getFullYear();
            return `${day}/${month}/${year}`;
        };
        const start_date = startDate ? formatDate(startDate) : '';
        const end_date = endDate ? formatDate(endDate) : '';
        // const categoriesQuery = selectedCategories 
        //     ? selectedCategories.map(cat => `categories=${cat.value}`).join('&')
        //     : '';

        // const query = {
        //     client: clientId,
        //     date_start: start_date,
        //     date_end: end_date,
        //     text: Validationkeyword,
        //     subject: subject,
        //     swot: swot,
        //     qualitative: qualitative,
        //     mention: mention,
        // };

        // const queryString = Object.entries(query)
        //     .filter(([_, value]) => value)
        //     .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
        //     .join('&');

        // const fullQuery = `${queryString}&${categoriesQuery}`;

        // const response = await get(`/clipping/report/?${fullQuery}`);
        const response = await get('/clipping/report/', {
          client: clientId,
          date_start: start_date,
          date_end: end_date,
          category: categoryId,
          text : Validationkeyword,
          subject: subject,
          swot: swot,
          qualitative: qualitative,
          mention: mention,
         });

        return response; // Corrige para retornar a resposta completa
    } catch (error) {
        console.error('Erro ao carregar o relatório:', error.response);
        throw error;
    }
  };

  export const updateClippingCategories = async (clippingId, categories) => {
    try {
        const response = await patch(`/clipping/${clippingId}/`, {
            categories: categories
        });
        return response;
    } catch (error) {
        console.error('Erro ao atualizar categorias do clipping:', error.response);
        throw error;
    }
  };

  export const exportClippings = async (selectedCards, email) => {
    try {
        const payload = {
            clippings: selectedCards,
            email: email || '',
        };
        const response = await post('/clipping/export_excel/', payload);
        return response;
    } catch (error) {
        console.error('Erro ao exportar os clippings:', error.response);
        throw error;
    }
};

  export const deleteEvaluation = async (clippingId) => {
    try {
      const payload = Array.isArray(clippingId)
        ? { ids: clippingId.map(Number) }
        : { ids: [Number(clippingId)] };
  
      const response = await post(`/clipping/delete_evaluation/`, payload);
      return response;
    } catch (error) {
      console.error('Erro ao limpar avaliação:', error.response);
      throw error;
    }
  };

  export const deleteClippings = async (clippingIds) => {
    try {
      const response = await post('/clipping/delete_clippings/', { ids: clippingIds });
      return response;
    } catch (error) {
      console.error('Erro ao excluir clippings:', error.response);
      throw error;
    }
  };